import { Injectable } from '@angular/core';
import { Margins, UnorderedListType } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfConfigService } from './pdf-config.service';
import { BinaryFontsService } from './binary-fonts.service';
import {MandatFormService} from "../mandat-form.service";
import pdfFonts from "pdfmake/build/vfs_fonts";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMandatOldService {
  pdfToOpen: any;
  binaryPdf: any;

  constructor(
    private _pdfConfigService: PdfConfigService,
    private _mandatFormService: MandatFormService,
    private _binaryFontsService: BinaryFontsService
  ) {
    (<any>pdfMake).vfs = this._binaryFontsService.fonts;
  }

  createPdf(img: any, client: any, date: any): void {
    // Schriftarten und Layout setzen
    (pdfMake as any).fonts = this._pdfConfigService.getFontConfig('mandat');
    (pdfMake as any).tableLayouts = this._pdfConfigService.getTableLayouts('mandat');

    const docDefinition = {
      content: [
        {
          table: {
            widths: ['*'],
            style: 'header',
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'Brokermandat \n Auftrag und Vollmacht',
                  fontSize: 12,
                  color: '#0075bf',
                  marginLeft: -5,
                  marginBottom: 11,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#0075bf', '#0075bf', '#0075bf', '#0075bf'],
                  padding: 0,
                }
              ],
            ]
          },
        },
        {
          image: this._binaryFontsService.pdfLogo,
          width: 81.0850394,
          absolutePosition: { x: 492, y: 22.11024 },
        },
        // Mandatsinhalt
        {
          columns: [
            { width: 107, text: 'Zwischen' },
            {
              width: 'auto',
              text:
                this._mandatFormService.personalInformation.first_name + ' ' +
                this._mandatFormService.personalInformation.last_name + '\n' +
                this._mandatFormService.personalInformation.street + ', ' +
                this._mandatFormService.personalInformation.post_code + ' ' +
                this._mandatFormService.personalInformation.city + '\n' +
                this._mandatFormService.personalInformation.email + ', ' +
                this._mandatFormService.personalInformation.phone + '\n' +
                'nachstehend Mandant genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        // BSC Broker
        {
          columns: [
            { width: 107, text: 'und der' },
            {
              width: 'auto',
              text: 'BSC Broker Service Center GmbH \n Picardiestrasse 3A, 5040 Schöftland \n nachstehend BSC genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        // Life Gate
        {
          columns: [
            { width: 107, text: 'in Zusammenarbeit \n mit dem Broker' },
            {
              width: 'auto',
              text: 'Life Gate Consulting AG, Lindenhofweg 4, 8806 Bäch \n nachstehend Broker genannt'
            }
          ],
          style: 'columns',
          columnGap: 0
        },
        // Paragraphen und Liste
        {
          text: 'Mit Wirkung zum Datum der Unterzeichnung beauftragt die Auftraggeberin die Beauftragte und Mitbeauftragte mit der Überprüfung, Gestaltung, Koordination sowie dem Abschluss und der laufenden sämtlicher Versicherungsverträge.',
          style: 'firstparagraph'
        },
        {
          text: 'Dies gilt auch für die Tochtergesellschaften der Auftraggeberin. Sowohl die Beauftragte als auch die Mitbeauftragte sind ermächtigt, dazu im Namen der Auftraggeberin aufzutreten.',
          style: 'paragraph'
        },
        {
          text: 'Diese Vollmacht berechtigt sowohl die Beauftragte wie auch die Mitbeauftragte insbesondere,',
          style: 'paragraph'
        },
        {
          markerColor: '#0075bf',
          style: 'list',
          type: 'square' as UnorderedListType,
          ul: [
            'Versicherungsofferten einzuholen;',
            'Mit den Anbietenden zu verhandeln;',
            'Versicherungen nach Rücksprache mit der Auftraggeberin zu platzieren und zu kündigen; und',
            'In Schadenfällen die Interessen der Auftraggeberin zu vertreten (einschliesslich Einsicht in das gesamte Dossier).',
          ]
        },
        {
          text: 'Die Vollmacht umfasst auch die Beschaffung und die Weitergabe erforderlicher Risikoinformationen aus bestehenden und abgelaufenen Versicherungsverträgen.',
          style: 'paragraph'
        },
        {
          text: 'Der Auftrag ist gemäss den Bestimmungen des schweizerischen Obligationenrechts jederzeit widerrufbar.',
          style: 'paragraph'
        },
        {
          text: 'Die Auftraggeberin bestätigt mit ihrer Unterschrift, Folgendes erhalten zu haben:',
          style: 'paragraph'
        },
        {
          markerColor: '#0075bf',
          style: 'list',
          type: 'square' as UnorderedListType,
          ul: [
            'Informationen nach Art. 45 Versicherungsaufsichtsgesetz (VAG) von der Beauftragten (Anhang 1)',
            'Informationen nach Art. 45 Versicherungsaufsichtsgesetz (VAG) von der Mitbeauftragten',
            'Offenlegung der Entschädigung nach Art. 45b Versicherungsaufsichtsgesetz (VAG) (Anhang 2)',
            'Allgemeine Geschäftsbedingungen (AGB), Version Co-Broker (Anhang 3)',
          ]
        },
        // Signaturtabelle
        {
          style: 'table',
          layout: 'tableLayout',
          table: {
            widths: ['*', 20, '*'],
            heights: [45, 45, 45, 45],
            body: [
              [
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  marginTop: 35,
                  text: 'Dübendorf, ' + date
                },
                { alignment: 'bottom', border: [false, false, false, false], text: '' },
                {
                  alignment: 'bottom',
                  border: [false, false, false, true],
                  stack: [
                    { image: img, alignment: 'center', width: 120, margin: [0, 0, 0, 0] }
                  ]
                },
              ],
              [
                { hLineWidth: 2, border: [false, true, false, false], text: 'Ort und Datum' },
                { border: [false, false, false, false], text: '' },
                { border: [false, true, false, false], text: 'Unterschrift Mandant' },
              ],
              [
                { border: [false, true, false, false], text: 'Ort und Datum' },
                { border: [false, false, false, false], text: '' },
                { border: [false, true, false, false], text: 'Unterschrift Broker' },
              ],
              [
                { border: [false, true, false, false], text: 'Ort und Datum' },
                { border: [false, false, false, false], text: '' },
                { border: [false, true, false, false], text: 'Unterschrift BSC Broker Service Center GmbH' },
              ],
            ]
          }
        },
        {
          absolutePosition: { x: 492, y: 770 },
          table: {
            widths: [69],
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'Seite 1 von 1\nVersion 26.01.2024',
                  fontSize: 8,
                  color: '#000000',
                  marginLeft: -5,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#0075bf', '#0075bf', '#0075bf', '#0075bf'],
                  padding: 0,
                }
              ],
            ]
          },
        },
      ],
      defaultStyle: {
        font: 'FiraSans',
        fontSize: 8,
        color: 'black',
        lineHeight: 1.2,
      },
      styles: {
        paragraph: { margin: [0, 0, 0, 11] as Margins },
        firstparagraph: { margin: [0, 11, 0, 0] as Margins },
        list: { margin: [0, 0, 0, 11] as Margins },
        columns: { margin: [0, 0, 0, 11] as Margins }
      },
      pageMargins: [99.2126, 22.11024, 115.0315, 0] as Margins,
    };

    this.pdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data: any) => {
      this.binaryPdf = data;
    });
  }
}
