import { Component } from '@angular/core';

@Component({
  selector: 'app-campagne-success',
  imports: [],
  templateUrl: './campagne-success.component.html',
  styleUrl: './campagne-success.component.scss'
})
export class CampagneSuccessComponent {

}
