import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ThemeService} from "./shared/services/theme.service";
import {NgStyle} from "@angular/common";
import {HeaderComponent} from "./shared/layout/header/header.component";
import {RouterOutlet} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    HeaderComponent,
    RouterOutlet
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'lifegate-broker-app';

  public primaryColor: any;
  public secondaryColor: any;
  public surfaceColor: any;
  private subscription: any = Subscription;

  constructor(private _themeService: ThemeService) {
  }

  ngOnInit() {
    this.getThemeColors()
  }

  getThemeColors() {
    /**
     * subscribe to colorObservable from themeService and update the primaryColor, secondaryColor, and surfaceColor properties.
     * set the --primaryColor, --secondaryColor, and --surfaceColo CSS variables on the document.body
     * @param {any} data - an object containing primary, secondary, and surface color values in hex color code
     */
    this.subscription = this._themeService.colorObservable.subscribe((data: any) => {
        this.primaryColor = data.primary;
        this.secondaryColor = data.secondary;
        this.surfaceColor = data.surface;
        document.body.style.setProperty('--primaryColor', data.primary);
        document.body.style.setProperty('--secondaryColor', data.secondary);
        document.body.style.setProperty('--surfaceColor', data.surface);
        document.body.style.setProperty('--color-primary', data.primary);
        document.body.style.setProperty('--color-secondary', data.secondary);
        document.body.style.setProperty('--color-surface', data.surface);

        console.log(document.body.style);
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
