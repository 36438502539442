import { Injectable } from '@angular/core';
import {Margins, TDocumentDefinitions, UnorderedListType} from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfConfigService } from './pdf-config.service';
import { BinaryFontsService } from './binary-fonts.service';
import pdfFonts from "pdfmake/build/vfs_fonts";
import {MandatFormService} from "../mandat-form.service";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMandatService {
  mandatPdfToOpen: any;
  mandatBinaryPdf: any;

  constructor(
    private _pdfConfigService: PdfConfigService,
    private _mandatFormService: MandatFormService,
    private _binaryFontsService: BinaryFontsService
  ) {
    (<any>pdfMake).vfs = this._binaryFontsService.fonts;
  }


  createPdf(img: any, date: any): void {
    (pdfMake as any).fonts = this._pdfConfigService.getFontConfig('mandat'); // Auch hier Arial verwenden
    (pdfMake as any).tableLayouts = this._pdfConfigService.getTableLayouts('mandat');

    const docDefinition: TDocumentDefinitions = {
      content: [
        {
          svg: this._binaryFontsService.howdenLogo.logo,
          width: 81.0850394,
          absolutePosition: { x: 492, y: 22 },
        },
        {
          svg: this._binaryFontsService.lifegateLogo.logo,
          width: 40,
          absolutePosition: { x: 28, y: 22 },
        },
        {
          text: 'Brokermandat',
          style: 'headline',
          margin: [0, 80, 0, 0],
        },
        {
          text: 'Auftrag und Vollmacht',
          style: 'headlineMargin',
          margin: [0, 0, 0, 28],
          color: '#998542'
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: 'Auftraggeberin', style: 'header' },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: 'Beauftragte', style: 'header',},
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: 'Mitbeauftragte', style: 'header' },
              ],
              [
                { border: [false, false, false, false],
                  margin: [0, 0, 0, 12],
                  text:
                    this._mandatFormService.personalInformation.first_name + ' ' +
                    this._mandatFormService.personalInformation.last_name + '\n' +
                    this._mandatFormService.personalInformation.street + '\n' +
                    this._mandatFormService.personalInformation.post_code + ' ' +
                    this._mandatFormService.personalInformation.city + '\n' +
                    this._mandatFormService.personalInformation.email + '\n' +
                    this._mandatFormService.personalInformation.phone
                },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: 'BSC Broker Service Center GmbH\nPicardiestrasse 3A\nCH-5040 Schöftland' },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: 'Life Gate Consulting AG\nLindenhofweg 4\nCH – 8806 Bäch SZ\nFINMA Nr. F01097803' },
              ],
            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
          }
        },
        // Paragraphen und Liste
        {
          text: 'Mit Wirkung zum Datum der Unterzeichnung beauftragt die Auftraggeberin die Beauftragte und Mitbeauftragte mit der Überprüfung, Gestaltung, Koordination sowie dem Abschluss und der laufenden sämtlicher Versicherungsverträge.',
          style: 'paragraph'
        },
        {
          text: 'Dies gilt auch für die Tochtergesellschaften der Auftraggeberin. Sowohl die Beauftragte als auch die Mitbeauftragte sind ermächtigt, dazu im Namen der Auftraggeberin aufzutreten.',
          style: 'paragraph'
        },
        {
          text: 'Diese Vollmacht berechtigt sowohl die Beauftragte wie auch die Mitbeauftragte insbesondere,',
          style: 'paragraph'
        },
        {
          markerColor: '#998542',
          style: 'list',
          margin: [0, 0, 0, 12],
          type: 'bullet' as UnorderedListType,
          ul: [
            'Versicherungsofferten einzuholen;',
            'Mit den Anbietenden zu verhandeln;',
            'Versicherungen nach Rücksprache mit der Auftraggeberin zu platzieren und zu kündigen; und',
            'In Schadenfällen die Interessen der Auftraggeberin zu vertreten (einschliesslich Einsicht in das gesamte Dossier).',
          ]
        },
        {
          text: 'Die Vollmacht umfasst auch die Beschaffung und die Weitergabe erforderlicher Risikoinformationen aus bestehenden und abgelaufenen Versicherungsverträgen.',
          style: 'paragraph'
        },
        {
          text: 'Der Auftrag ist gemäss den Bestimmungen des schweizerischen Obligationenrechts jederzeit widerrufbar.',
          style: 'paragraph'
        },
        {
          text: 'Die Auftraggeberin bestätigt mit ihrer Unterschrift, Folgendes erhalten zu haben:',
          style: 'paragraph'
        },
        {
          markerColor: '#998542',
          style: 'list',
          margin: [0, 0, 0, 12],
          type: 'bullet' as UnorderedListType,
          ul: [
            'Informationen nach Art. 45 Versicherungsaufsichtsgesetz (VAG) von der Beauftragten (Anhang 1)',
            'Informationen nach Art. 45 Versicherungsaufsichtsgesetz (VAG) von der Mitbeauftragten',
            'Offenlegung der Entschädigung nach Art. 45b Versicherungsaufsichtsgesetz (VAG) (Anhang 2)',
            'Allgemeine Geschäftsbedingungen (AGB), Version Co-Broker (Anhang 3)',
          ]
        },
        {
          style: 'table',
          table: {
            widths: ['*', 110, 20, '*', 110, 20, '*', 110],
            body: [
              [
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Ort, Datum,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text:  'Dübendorf, ' + date,},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Schöftland,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Ort, Datum,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text: '',},
              ],
            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
            hLineColor: (i, node) => '#173F35', // horizontale Linien in Rot
            vLineColor: (i, node) => '#173F35', // vertikale Linien in Rot
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', 20, '*', 20, '*'],
            body: [
              [
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Auftraggeberin', style: 'title' },
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: '',},
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Beauftragte', style: 'title' },
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: '',},
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Mitbeauftragte', style: 'title' },
              ],
              [
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: this._mandatFormService.personalInformation.first_name + ' ' + this._mandatFormService.personalInformation.last_name },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: ''},
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: '' },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: ''},
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: '' },
              ],
              [
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'Vorname und Name des Kunden', style: 'title', color: '#998542'},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'BSC Broker Service Center GmbH', style: 'title', color: '#000000'},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'Life Gate Consulting AG', style: 'title', color: '#998542'},
              ],
              [
                { border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                  stack: [
                    {
                      image: img,
                      alignment: 'center',
                      width: 120,
                      margin: [0, 0, 0, 0]
                    }
                  ]
                },
                { border: [false, false, false, false], margin: [0, 5, 0, 5], text: '',},
                { border: [false, false, false, true], margin: [0, 5, 0, 5], text: ''},
                { border: [false, false, false, false], margin: [0, 5, 0, 5], text: '',},
                { border: [false, false, false, true], margin: [0, 5, 0, 5], text: ''},
              ],

            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
            hLineColor: (i, node) => '#173F35', // horizontale Linien in Rot
            vLineColor: (i, node) => '#173F35', // vertikale Linien in Rot
          }
        },
        {
          absolutePosition: { x: 30, y: 780 },
          table: {
            widths: [450, '*'],
            body: [
              [
                {
                  border: [false, true, false, false],
                  margin: [0, 12, 0, 0],
                  text: 'Beauftragte ist eine Zweigniederlassung der Howden Schweiz AG - Zug',
                  font: 'Georgia',
                  bold: true,
                  fontSize: 9,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                },
                {
                  border: [false, true, false, false],
                  margin: [0, 12, 0, 0],
                  text: '',
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                }
              ],
              [
                {
                  border: [false, false, false, false],
                  text: 'FINMA Nr.: F01046452 | howdengroup.com - Version 12.2024',
                  fontSize: 8,
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                  padding: 0,
                },
                {
                  border: [false, false, false, false],
                  text: 'Seite 1 von 1',
                  fontSize: 8,
                  alignment: 'right',
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                  padding: 0,
                }
              ],
            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0
          }
        },

      ],
      defaultStyle: {
        font: 'Arial',
        fontSize: 10,
        color: '#173F35',
        lineHeight: 1,
      },
      styles: {
        // Bestehende Stile bleiben erhalten
        paragraph: { font: 'Arial', marginBottom: 12, color: '#173F35' },

        headline: { font: 'Georgia', fontSize: 28, color: '#173F35' },
        headlineMargin: { font: 'Georgia', fontSize: 28, marginBottom: 12, color: '#173F35' },
        header: { font: 'Arial', bold: true, fontSize: 12, marginBottom: 10, color: '#173F35' },
        title: { font: 'Arial', bold: true, fontSize: 10, marginBottom: 10, color: '#173F35' },
      },

      pageMargins: [30, 30, 30, 0] as Margins,
    } as TDocumentDefinitions;

    this.mandatPdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data: any) => {
      this.mandatBinaryPdf = data;
    });
  }
}
