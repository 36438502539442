

@if(!success && !error){
  <mat-dialog-content>
    <h2 class="pb-2">Übermitteln Sie ihre Kontaktdaten</h2>
    <p class="pb-4">
      Wir setzen uns so bald wie möglich mit Ihnen in Verbindung, um Ihnen unverbindlich die Vorteile unserer Plattform vorzustellen.
    </p>
    <form #contactForm="ngForm">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Vorname</mat-label>
        <input matInput [(ngModel)]="formData.firstName" name="firstName" required />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nachname</mat-label>
        <input matInput [(ngModel)]="formData.lastName" name="lastName" required />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>Telefon</mat-label>
        <input matInput [(ngModel)]="formData.phone" name="phone" required />
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>E-Mail</mat-label>
        <input matInput [(ngModel)]="formData.email" name="email" required email />
      </mat-form-field>
    </form>

    @if(error) {
      <p class="pb-4">
        Das Formular konnte nicht übermittelt werden. Überprüfen Sie bitte Ihre Eingaben.
      </p>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="button-rounded button-small">Abbrechen</button>
    <button mat-flat-button color="primary" class="button-rounded button-small" (click)="onSubmit()" [disabled]="!contactForm.valid">Senden</button>
    @if(isLoading){
      <mat-progress-bar mode="indeterminate" style="height: 4px !important; position: absolute !important; bottom: 0 !important; left: 0 !important;"></mat-progress-bar>
    }
  </mat-dialog-actions>
}


@if(false) {
  <mat-dialog-content>
    <h2 class="pb-2">Vielen Dank</h2>
    <p class="pb-4">
      Ihre Kontaktdaten wurden erfolgreich übermittelt. Wir melden uns so bald wie möglich bei Ihnen.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" mat-dialog-close class="button-rounded button-small">Schliessen</button>
  </mat-dialog-actions>
}
