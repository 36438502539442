import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';

export type PdfType = 'mandat' | 'mobiliar' | 'agb';

@Injectable({
  providedIn: 'root'
})
export class PdfConfigService {
  constructor() {}

  getFontConfig(type: PdfType): any {
    if (type === 'mandat') {
      return {
        Georgia: {
          normal: 'Georgia-Regular.ttf',
          bold: 'Georgia-Bold.ttf',
          italics: 'Georgia-Regular.ttf',
          bolditalics: 'Georgia-Regular.ttf'
        },
        Arial: {
          normal: 'Arial-Regular.ttf',
          bold: 'Arial-Bold.ttf',
          italics: 'Arial-Regular.ttf',
          bolditalics: 'Arial-Regular.ttf'
        },
      };
    } else {
      // mobiliar und agb nutzen Arial
      return {
        Arial: {
          normal: 'Arial-Regular.ttf',
          bold: 'Arial-Bold.ttf',
          italics: 'Arial-Regular.ttf',
          bolditalics: 'Arial-Regular.ttf'
        },
      };
    }
  }

  getTableLayouts(type: PdfType): any {
    if (type === 'mandat') {
      return {
        tableLayout: {
          hLineWidth: (i: number, node: any) => 0.5,
          vLineWidth: (i: number) => 0,
          hLineColor: (i: number) => 'black',
        }
      };
    } else {
      // Für mobiliar und agb
      return {
        tableLayout: {
          hLineWidth: (i: number, node: any) => i === 0 ? 0 : 0.5,
          vLineWidth: (i: number) => 0,
          hLineColor: (i: number) => 'black',
        }
      };
    }
  }
}
