import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor(private _platform: Platform) {}

  viewPdf(pdf: any): void {
    if (this._platform.ANDROID) {
      pdf.download();
    } else {
      pdf.open();
    }
  }
}
