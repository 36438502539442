import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {SendgridService} from "../services/sendgrid.service";
import {EmailService} from "../services/email.service";
import {MatProgressBar} from "@angular/material/progress-bar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormField,
    FormsModule,
    MatInput,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatLabel,
    MatProgressBar
  ],
  templateUrl: './contact-dialog.component.html',
  styleUrl: './contact-dialog.component.scss'
})
export class ContactDialogComponent {
  success = false;
  error = false;
  isLoading = false;

  formData = {
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
    email: '',
  };

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _emailService: EmailService,
    private router: Router,
  ) {}



  onSubmit() {
    this.isLoading = true;
    this._emailService.sendContactEmail(this.formData).subscribe({
      next: () => {
        this.success = true;
        this.isLoading = false;
        this.dialogRef.close();
        this.router.navigate(['campagne/success']);
        console.log('E-Mail erfolgreich gesendet');
      },
      error: (error:any) => {
        this.isLoading = false;
        this.error = true;
        console.error('Fehler beim Senden der E-Mail:', error);
      },
    });
  }

}
