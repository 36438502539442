<div class="container">
  <div class="row pt-xl">
    <div class="col">
      <h1 class="pb-sm">Impressum</h1>
      <div class="col-lg-9">
        <h2 class="pt-sm">Verantwortliche Instanz:</h2>
        <p>Life Gate Consulting AG<br>
          Lindenhofweg 4<br>
          8806 Bäch SZ<br>
          Schweiz<br>
          Finma Nr. F01097803<br>
          Tel: +41 44 787 67 04</p>

        <h2 class="pt-sm">Vertretungsberechtigte Personen Geschäftsleitung:</h2>
        <p>R. Schneider<br>
          N. Buchs<br>
          R. Schwarzmann</p>

        <h2 class="pt-sm">Name des Unternehmens:</h2>
        <p>Life Gate Consulting AG</p>

        <h2 class="pt-sm">Umsatzsteuer-Identifikationsnummer:</h2>
        <p>CHE-433.900.438 MWST</p>

        <h2 class="pt-sm">Haftungsausschluss</h2>
        <p>Der Autor übernimmt keine Gewähr für die Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
          Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor wegen Schäden materieller oder
          immaterieller Art, die aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen,
          durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle
          Angebote sind freibleibend. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte
          Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
          oder endgültig einzustellen.</p>

        <h2 class="pt-sm">Haftungsausschluss für Inhalte und Links</h2>
        <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche
          Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf
          eigene Gefahr des jeweiligen Nutzers.</p>

        <h2 class="pt-sm">Urheberrechtserklärung</h2>
        <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser Website,
          gehören ausschliesslich Life Gate AG oder den speziell genannten Rechteinhabern. Für die Reproduktion
          jeglicher Elemente ist die schriftliche Zustimmung des Urheberrechtsträgers im Voraus einzuholen.</p>

        <h2 class="pt-sm">Nutzungsbedingungen</h2>
        <p>Weitere Informationen zur Nutzung unserer Onlineangebote und Dienstleistungen finden Sie in unserer
          Beratungsvereinbarung sowie der Datenschutzerklärung, sowie allfälligen produktspezifischen Unterlagen.</p>


      </div>
    </div>
  </div>
</div>
