import { Injectable } from '@angular/core';
import { Margins } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfConfigService } from './pdf-config.service';
import { BinaryFontsService } from './binary-fonts.service';
import pdfFonts from "pdfmake/build/vfs_fonts";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMobiliarService {
  mobiPdfToOpen: any;
  mobiBinaryPdf: any;

  constructor(
    private _pdfConfigService: PdfConfigService,
    private _binaryFontsService: BinaryFontsService
  ) {
    (<any>pdfMake).vfs = this._binaryFontsService.fonts;
  }

  createPdfMobiliar(img: any, date: any): void {
    (pdfMake as any).fonts = this._pdfConfigService.getFontConfig('mobiliar');
    (pdfMake as any).tableLayouts = this._pdfConfigService.getTableLayouts('mobiliar');

    const docDefinition = {
      content: [
        {
          image: this._binaryFontsService.mobiliarLogo,
          width: 131.83,
          absolutePosition: { x: 402, y: 28.3 },
        },
        { text: 'Verwaltung der Versicherungsverträge der Mobiliar', style: 'firstparagraph' },
        { text: 'Sehr geehrte Kundin, sehr geehrter Kunde', style: 'paragraph' },
        {
          text:
            'Sie haben sich entschieden, die Betreuung Ihrer Versicherungsverträge künftig einem\n' +
            'Versicherungsmakler anzuvertrauen. Die von Ihnen gewählte Maklerfirma ist Partner der\n' +
            'Mobiliar und hat einen Zusammenarbeitsvertrag mit unserer Gesellschaft',
          style: 'paragraph'
        },
        {
          text:
            'Da Sie weiterhin Versicherungsnehmer der Mobiliar bleiben, werden Ihre\n' +
            'Versicherungsverträge von der bisher zuständigen Generalagentur der Mobiliar verwaltet.',
          style: 'paragraph'
        },
        { text: 'Sie haben jedoch die Möglichkeit, eine andere Generalagentur zu wählen.', style: 'paragraph' },
        {
          text:
            'Wir bitten Sie, diesen Entscheid gemeinsam mit Ihrer Maklerfirma zu treffen und danken\n' +
            'Ihnen für die Rücksendung dieses Formulars. ',
          style: 'paragraph'
        },
        { text: 'Freundliche Grüsse', style: 'paragraph' },
        {
          text:
            'Schweizerische Mobiliar Versicherungsgesellschaft AG\n' +
            'Makler Service Center ',
          style: 'paragraph'
        },
        {
          layout: 'tableLayout',
          table: {
            widths: ['*', '*'],
            body: [
              [
                [
                  {
                    border: [false, false, false, false],
                    text: 'Christoph Kopp',
                    marginLeft: -5,
                    marginTop: 25,
                    padding: 0,
                  },
                  {
                    border: [false, false, false, false],
                    fontSize: 9,
                    marginLeft: -5,
                    marginBottom: 14,
                    text: 'Leiter Direktion Maklergeschäft'
                  }
                ],
                [
                  {
                    border: [false, false, false, false],
                    text: 'Stephan Schmucki',
                    marginLeft: -5,
                    marginTop: 25,
                    padding: 0,
                  },
                  {
                    border: [false, false, false, false],
                    fontSize: 9,
                    marginLeft: -5,
                    marginBottom: 14,
                    text: 'Leiter Makler Service Center'
                  }
                ],
              ],
            ]
          }
        },
        {
          text: 'Meine / unsere bei der Mobiliar platzierten Versicherungsverträge sollen',
          style: 'paragraph',
          marginTop: 27.7,
        },
        {
          text: 'auf der bisher zuständigen Generalagentur der Mobiliar weitergeführt werden.',
          style: 'paragraph',
          marginLeft: 34.66,
        },
        {
          image: this._binaryFontsService.uncheckdIcon,
          width: 7.9,
          marginTop: -23,
          marginLeft: 17.9,
        },
        {
          text: 'auf der Generalagentur in Dietikon Zürich geführt werden.',
          style: 'paragraph',
          marginLeft: 34.66,
          marginTop: 23,
        },
        {
          image: this._binaryFontsService.checkedIcon,
          width: 8.5,
          marginTop: -23,
          marginLeft: 17.9,
        },
        {
          text: 'Datum, Stempel, Unterschrift',
          style: 'paragraph',
          marginTop: 27.55,
        },
        {
          columns: [
            { text: 'Versicherungsnehmer', bold: true },
            { text: 'BSC Broker Service Center GmbH', bold: true },
          ]
        },
        { image: img, width: 200, marginLeft: -10 },
        { text: date },
      ],
      defaultStyle: {
        font: 'Arial',
        fontSize: 11,
        color: 'black',
        lineHeight: 1.2,
      },
      styles: {
        paragraph: { margin: [0, 0, 0, 11] as Margins },
        firstparagraph: {
          fontSize: 11,
          bold: true,
          margin: [0, 0, 0, 21] as Margins,
        },
      },
      pageMargins: [85.5, 141.17, 74, 0] as Margins,
    };

    this.mobiPdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data: any) => {
      this.mobiBinaryPdf = data;
    });
  }
}
