<!-- Google tag (gtag.js) event -->
<script>
  gtag('event', 'conversion_event_contact', {
    // <event_parameters>
  });
</script>


<ng-container >
  <div class="container">
    <div class="row pt-xl">
      <div class="col">
        <h1 class="pb-sm">Vielen Dank für Ihre Kontaktaufnahme!</h1>
        <h2 class="col-lg-9 pb-sm">
          Ihre Nachricht wurde erfolgreich übermittelt.<br>
          Wir freuen uns über Ihr Interesse und werden uns schnellstmöglich bei Ihnen melden. <br>
          Sollten Sie in der Zwischenzeit dringende Fragen haben, kontaktieren Sie uns per Telefon unter +41 44 552 35 00.<br><br>
          Wir sind Montag bis Freitag von 8 Uhr bis 18 Uhr für Sie erreichbar.
        </h2>
      </div>
    </div>
  </div>
</ng-container>
