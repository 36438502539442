import {Component} from '@angular/core';
import {ViewportScroller} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatAnchor} from "@angular/material/button";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatAnchor
  ]
})
export class HomepageComponent {

}
