import { Injectable } from '@angular/core';
import {Margins, TDocumentDefinitions, UnorderedListType} from 'pdfmake/interfaces';
import pdfMake from 'pdfmake/build/pdfmake';
import { PdfConfigService } from './pdf-config.service';
import { BinaryFontsService } from './binary-fonts.service';
import pdfFonts from "pdfmake/build/vfs_fonts";
import {MandatFormService} from "../mandat-form.service";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfAvbService {

  agbPdfToOpen: any;
  agbBinaryPdf: any;

  constructor(
    private _pdfConfigService: PdfConfigService,
    private _mandatFormService: MandatFormService,
    private _binaryFontsService: BinaryFontsService
  ) {
    (<any>pdfMake).vfs = this._binaryFontsService.fonts;
  }


  createPdf(img: any, date: any): void {
    (pdfMake as any).fonts = this._pdfConfigService.getFontConfig('mandat'); // Auch hier Arial verwenden
    (pdfMake as any).tableLayouts = this._pdfConfigService.getTableLayouts('mandat');


    const docDefinition: TDocumentDefinitions = {
      header: [
        {
          svg: this._binaryFontsService.howdenLogo.logo,
          width: 81.0850394,
          absolutePosition: { x: 492, y: 22 },
        },
        {
          svg: this._binaryFontsService.lifegateLogo.logo,
          width: 40,
          absolutePosition: { x: 28, y: 22 },
        }
      ],
      footer: (currentPage: number, pageCount: number) => {
        return {
          margin: [30, 0, 30, 0],
          table: {
            widths: [450, '*'],
            body: [
              [
                {
                  border: [false, true, false, false],
                  text: 'BSC Co-Brokermandat – Version 12.2024',
                  fontSize: 8,
                  margin: [0, 12, 0, 0],
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                  padding: 0,
                },
                {
                  border: [false, true, false, false],
                  text: `Seite ${currentPage} von ${pageCount}`,
                  fontSize: 8,
                  margin: [0, 12, 0, 0],
                  alignment: 'right',
                  borderSize: [0.1, 0.1, 0.1, 0.1],
                  borderColor: ['#E0EC89', '#E0EC89', '#E0EC89', '#E0EC89'],
                  padding: 0,
                }
              ],
            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0
          }
        };
      },

      content: [
        {
          text: 'Anhang 1',
          style: 'headline',
          margin: [0, 50, 0, 0],
          color: '#998542'
        },
        {
          text: 'Informationen nach Art. 45\n' +
            'Versicherungsaufsichtsgesetz (VAG) der\n' +
            'Beauftragten',
          style: 'headlineMargin',
          margin: [0, 14, 0, 14],
        },
        {
          text: 'Ihre Ansprechpartnerin',
          style: 'header'
        },
        {
          text: 'BSC Broker Service Center, Picardiestrasse 3a, 5040 Schöftland («BSC») ist eine ungebundene Vermittlerin gemäss Art. 40 Abs. 2 VAG und eingetragen im von der Eidgenössischen Finanzmarktaufsicht FINMA geführten Register für Versicherungsvermittler unter der Nummer F01064059.',
          style: 'paragraph',
          alignment: 'justify',
        },
        {
          text: 'Vertragsbeziehungen',
          style: 'header'
        },
        {
          text: 'Als unabhängiger Versicherungsbroker arbeitet BSC je nach Versicherungsbedarf der Auftraggeberin mit verschiedenen Anbietern von Versicherungsdienstleistungen in allen Versicherungszweigen (ausser der Rückversicherung) zusammen.',
          style: 'paragraph'
        },
        {
          text: 'Mit den Versicherungsunternehmen bestehen Zusammenarbeitsverträge, welche Form und Inhalt der Zusammenarbeitregeln. Sie enthalten keine Regelungen, welche die Unabhängigkeit von BSC einschränken könnten.',
          style: 'paragraph'
        },
        {
          text: 'Aus- und Weiterbildung',
          style: 'header'
        },
        {
          text: 'Gerne wird auf Wunsch eine Übersicht der von unseren Versicherungsvermittlern/-vermittlerinnen absolvierten Aus- und Weiterbildungen zur Verfügung gestellt. Bitte wenden Sie sich bei Bedarf an ausbildung-ch@howdengroup.com.',
          style: 'paragraph'
        },
        {
          text: 'Haftung',
          style: 'header'
        },
        {
          text: 'Die im Brokermandat als Beauftragte genannte Partei gilt als die Person, die für die Mitbeauftragte Person innendienst-Leistungen erbringt. Da die Beauftrage kein Risikomanagement und keine Beratung bei der Auftraggeberin vornimmt, kann sie im Rahmen und Umfang der gesetzlichen Bestimmungen nicht für Beratungsfehler haftbar gemacht werden. Mit Unterzeichnung des Mandates nimmt die Auftraggeberin dies zur Kenntnis.',
          style: 'paragraph'
        },
        {
          text: 'Datenschutz',
          style: 'header'
        },
        {
          text: 'Um die mit der Auftraggeberin vereinbarten Dienstleistungen erbringen zu können, werden die dafür notwendigen Personendaten (bspw. Name, Adresse, Telefonnummer, AHV-Nummer, Angaben über vergangene Schadenfälle, Gesundheitszustand, Lohnangaben etc.) bearbeitet und an Versicherungsunternehmen und/oder Vorsorgeeinrichtungen übermittelt. Dabei werden die anwendbaren Datenschutzbestimmungen eingehalten. Die Daten werden bei BSC physisch und/oder in elektronischer Form aufbewahrt. Die Zwecke der Datenbearbeitung, die Aufbewahrung der Daten, die Übermittlung der Daten und weitere wichtige Informationen finden sich in der Datenschutzerklärung: https://brokerservice.ch/datenschutz.',
          style: 'paragraph'
        },
        {
          text: 'Informationen nach Art. 45\n' +
            'Versicherungsaufsichtsgesetz (VAG) der\n' +
            'Mitbeauftragten',
          style: 'headlineMargin',
          margin: [0, 50, 0, 14],
          pageBreak: 'before'
        },
        {
          text: 'Ihre Ansprechpartnerin',
          style: 'header'
        },
        {
          text: 'Life Gate Consulting AG, Lindenhofweg 4, 8806 Bäch ist eine ungebundene Vermittlerin gemäss Art. 40 Abs. 2 VAG und eingetragen im von der Eidgenössischen Finanzmarktaufsicht FINMA geführten Register für Versicherungsvermittler unter der Nummer FINMA Nummer F01097803.',
          style: 'paragraph'
        },
        {
          text: 'Vertragsbeziehungen',
          style: 'header'
        },
        {
          text: 'Als unabhängiger Versicherungsbroker arbeitet Life Gate Consulting je nach Versicherungsbedarf der Auftraggeberin mit verschiedenen Anbietern von Versicherungsdienstleistungen in allen Versicherungszweigen (ausser der Rückversicherung) zusammen.',
          style: 'paragraph'
        },
        {
          text: 'Mit den Versicherungsunternehmen bestehen Zusammenarbeitsverträge, welche Form und Inhalt der Zusammenarbeit regeln. Sie enthalten keine Regelungen, welche die Unabhängigkeit von Life Gate Consulting einschränken könnten.',
          style: 'paragraph'
        },
        {
          text: 'Aus- und Weiterbildung',
          style: 'header'
        },
        {
          text: 'Gerne wird auf Wunsch eine Übersicht der von unseren Versicherungsvermittlern/-vermittlerinnen absolvierten Aus- und Weiterbildungen zur Verfügung gestellt. Bitte wenden Sie sich bei Bedarf an mandate@life-gate.ch.',
          style: 'paragraph'
        },
        {
          text: 'Haftung',
          style: 'header'
        },
        {
          text: 'Die im Brokermandat als Mitbeauftragte genannte Partei gilt als die Person, die für Nachlässigkeit, Fehler oder unrichtige Auskünfte im Zusammenhang mit ihrer Vermittlungstätigkeit haftbar gemacht werden kann.',
          style: 'paragraph'
        },
        {
          text: 'Datenschutz',
          style: 'header'
        },
        {
          text: 'Um die mit der Auftraggeberin vereinbarten Dienstleistungen erbringen zu können, werden die dafür notwendigen Personendaten (bspw. Name, Adresse, Telefonnummer, AHV-Nummer, Angaben über vergangene Schadenfälle, Gesundheitszustand, Lohnangaben etc.) bearbeitet und an Versicherungsunternehmen und/oder Vorsorgeeinrichtungen übermittelt. Dabei werden die anwendbaren Datenschutzbestimmungen eingehalten. Die Daten werden bei Life Gate Consulting physisch und/oder in elektronischer Form aufbewahrt. Die Zwecke der Datenbearbeitung, die Aufbewahrung der Daten, die Übermittlung der Daten und weitere wichtige Informationen finden sich in der Datenschutzerklärung: https://www.maklermandat.ch/datenschutz',
          style: 'paragraph'
        },
        {
          text: 'Anhang 2',
          style: 'headline',
          margin: [0, 50, 0, 0],
          color: '#998542',
          pageBreak: 'before'
        },
        {
          text: 'Offenlegung der Entschädigung nach Art.\n' +
            '45b Versicherungsaufsichtsgesetz (VAG)',
          style: 'headlineMargin',
          margin: [0, 14, 0, 14],
        },
        {
          text: 'Für die Erbringung der Dienstleistungen erhält BSC von den Versicherungsunternehmen oder Dritten eine marktübliche Courtage, welche in den offerierten Prämien enthalten ist. BSC vergütet daraus den vertraglich vereinbarten Anteil an die Mitbeauftragte (Bandbreite 50% bis max. 88%).',
          style: 'paragraph'
        },
        {
          text: 'Die Entschädigungen der Versicherungsunternehmen an BSC sind abhängig von der jeweiligen Prämienhöhe und der Versicherungsbranche. BSC hat mit den Versicherungsunternehmen oder Dritten folgende Courtagesätze vereinbart:',
          style: 'paragraph'
        },
        {
          style: 'table',
          table: {
            headerRows: 1, // erste Zeile ist der Header
            widths: ['*', '*', '*'],
            body: [
              // Header-Zeile
              [
                { text: 'Versicherungsbranche', style: 'georgiaBold' },
                { text: 'Courtagesätze (Bandbreiten)', style: 'georgiaBold' },
                { text: 'Berechnungsparameter', style: 'georgiaBold' }
              ],
              // Datenzeilen
              ['Berufliche Vorsorge', '7.0% bis max. 13.0%', 'Risiko- und Kostenprämie'],
              ['Berufliche Vorsorge - Rückdeckung firmeneigene Vorsorgeeinrichtungen', '1.0% bis max. 5.5%', 'Risiko- und Kostenprämie'],
              ['Financial Lines (z.B. D&O, Cyber)', '15.0%', 'Nettoprämie'],
              ['Haftpflicht', '12.5% bis max. 18.5%', 'Nettoprämie'],
              ['Kollektive Heilungskosten', '1.0% bis. max. 5.0%', 'Nettoprämie'],
              ['Kranken-Taggeld', '5.0% bis max. 9.0%', 'Nettoprämie'],
              ['Kredit / Kaution', '5.0% bis max. 15.0%', 'Nettoprämie'],
              ['Motorfahrzeug', '4.0% bis max. 15.0%', 'Nettoprämie'],
              ['Private Vorsorge', '0.5% bis max. 5.5%', 'Produktionssumme'],
              ['Rechtsschutz', '15.0%', 'Nettoprämie'],
              ['Reise', '8.0% bis max. 15.0%', 'Nettoprämie'],
              ['Sach / Gebäude / Hausrat', '10.0% bis max. 18.5%', 'Nettoprämie'],
              ['Technische (inkl. Bau)', '15.0%', 'Nettoprämie'],
              ['Transport', '15.0%', 'Nettoprämie'],
              ['UVG', '3.0% bis max. 6.5% (SUVA = 0%)', 'Nettoprämie'],
              ['UVG-Zusatz', '15.0% bis max. 17.5%', 'Nettoprämie']
            ]
          },
          layout: {
            // Füllt den Hintergrund je nach Zeilennummer:
            // rowIndex 0 = Header, danach alle Zeilen, bei denen (rowIndex+1) ungerade ist.
            fillColor: function (rowIndex, node, columnIndex) {
              if (rowIndex === 0) {
                return '#e1ec89'; // Hintergrundfarbe für den Header
              } else if ((rowIndex + 1) % 2 === 0){
                return '#f8fbe7'; // Hintergrundfarbe für ungerade Zeilen (1-indexiert)
              }
              return null;
            },
            // Definiert globale Einstellungen für horizontale und vertikale Linien,
            // sodass du diese nicht in jeder Zelle angeben musst.
            hLineWidth: function(i, node) {
              return 0.5;
            },
            vLineWidth: function(i, node) {
              return 0.5;
            },
            hLineColor: function(i, node) {
              return '#e1ec89';
            },
            vLineColor: function(i, node) {
              return '#e1ec89';
            },
            paddingLeft: function(i, node) { return 6; },
            paddingRight: function(i, node) { return 6; },
            paddingTop: function(i, node) { return 2; },
            paddingBottom: function(i, node) { return 2; }
          }
        },
        {
          text: 'Die Mitbeauftragte informiert die Auftraggeberin im Falle davon abweichender Courtagesätze und/oder Berechnungsparameter.',
          style: 'paragraph',
          margin: [0, 10, 0, 10],
        },
        {
          text: 'Auf Anfrage legt die Mitbeauftragte der Auftraggeberin die effektiv erhaltenen Beträge offen.',
          style: 'paragraph'
        },
        {
          text: 'Anhang 3',
          style: 'headline',
          margin: [0, 50, 0, 0],
          color: '#998542',
          pageBreak: 'before'
        },
        {
          text: 'Allgemeine Geschäftsbedingungen (AGB),\n' +
            'Version Co-Broker, Ausgabe vom 01.01.2024',
          style: 'headlineMargin',
          margin: [0, 14, 0, 14],
        },
        {

          columns: [
            {
              width: '50%',
              stack: [
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '1.', style: 'title' },
                    { text: 'Einleitung', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'BSC Broker Service Center GmbH und die Mitbeauf-tragte (im nachstehenden die Beauftragten genannt) sind ungebundene und unabhängige Versicherungsver-mittler für alle Versicherungssparten.', style: 'paragraphJustify' },
                { text: 'Die Beauftragten und Mitbeauftragten sowie deren in die Kundenberatung tätigen Mitarbeitenden sind bei der zuständigen Aufsichtsbehörde registriert und verfügen dadurch über die gesetzliche vorgeschriebene Zulas-sung.', style: 'paragraphJustify' },
                {
                  columns: [
                    { width: 20, text: '2.', style: 'title' },
                    { text: 'Geltungsbereich', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die vorliegenden allgemeinen Geschäftsbedingungen gelten für die Vertragsbeziehung zwischen den Beauf-tragten, der Mitbeauftragten und der Auftraggeberin im Zusammenhang mit der Erbringung von Vermittlungs-dienstleistungen, Beratungen und damit verwandten Tätigkeiten.', style: 'paragraphJustify' },
                {
                  columns: [
                    { width: 20, text: '3.', style: 'title' },
                    { text: 'Umfang und Ausführung der Leistungen', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Auftraggeberin beauftragt die Mitbeauftragten in Zu-sammenarbeit mit der Beauftragten als Dienstleisterin für die Mitbeauftragte, sie zu ihrem Versicherungsport-folio und damit verbundenen Bereichen zu beraten.', style: 'paragraphJustify' },
                { text: 'Dies umfasst neben der Beratung der Auftraggeberin auch in deren Namen mit Versicherungsunternehmen Verhandlungen zu führen, Offerten einzuholen und in Absprache mit der Auftraggeberin, die Versicherungen zu platzieren bzw. zu kündigen.', style: 'paragraphJustify' },
                { text: 'Allenfalls von der Auftraggeberin veranlasste grenz-überschreitende Reisetätigkeiten werden separat ver-gütet.', style: 'paragraphJustify' },
                { text: 'Der konkrete Umfang der Leistungen kann in einem se-paraten Dienstleistungskatalog definiert werden.', style: 'paragraphJustify' },
                {
                  columns: [
                    { width: 20, text: '4.', style: 'title' },
                    { text: 'Zusammenarbeit mit Gesellschaften der\nHowden-Gruppe und Dritten', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Beauftragte und die Mitbeauftragte ist ermächtigt, mit den Gesellschaften der Howden-Gruppe und auch Dritten zusammen zu arbeiten und die zur Ausführung der Zusammenarbeit erforderlichen Risikoinformationen mit diesen zu teilen.', style: 'paragraphJustify' },
              ]
            },
            {
              width: '50%',
              stack: [
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '5.', style: 'title' },
                    { text: 'Mitwirkungspflicht der Auftraggeberin', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Auftraggeberin ist verpflichtet, den Mitbeauftragten unaufgefordert alle für die Vertragserfüllung notwendi-gen Unterlagen und Informationen (z. B. Risikoverände-rungen) vollständig, inhaltlich richtig und rechtzeitig zur Verfügung zu stellen.', style: 'paragraphJustify' },
                {
                  columns: [
                    { width: 20, text: '6.', style: 'title' },
                    { text: 'Haftung', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Beauftragten und Mitbeauftragte haften für grob-fahrlässig oder absichtlich verursachte Schäden. Die Haftung für leichtfahrlässig verursachte Schäden ist im Rahmen des gesetzlich zulässigen Umfangs ausge-schlossen.', style: 'paragraphJustify' },
                { text: 'Die Beauftragten und Mitbeauftragte verfügen jeweils über die von der zuständigen Aufsichtsbehörde für un-gebundene Versicherungsvermittler vorgeschriebene Berufshaftpflichtversicherung.', style: 'paragraphJustify' },
                {
                  columns: [
                    { width: 20, text: '7.', style: 'title' },
                    { text: 'Schweigepflicht gegenüber Dritten', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Beauftragten und die Mitbeauftragte verpflichten sich im gesetzlich vorgeschriebenen oder vertraglich vereinbarten Umfang über alle vertraulichen, d.h. nicht allgemein bekannten und öffentlich zugänglichen Infor-mationen, die im Zusammenhang mit ihrer Tätigkeit für die Auftraggeberin bekannt werden, Stillschweigen zu bewahren, es sei denn, dass die Auftraggeberin sie von der Schweigepflicht entbindet.', style: 'paragraphJustify' },
                { text: 'Die Beauftragten und Mitbeauftragten dürfen Berichte, Gutachten und sonstige schriftliche Äusserungen über die Ergebnisse ihrer Tätigkeit Dritten nur mit Einwilli-gung der Auftraggeberin aushändigen.', style: 'paragraphJustify' },
                { text: 'Die Auftraggeberin stimmt zu, dass die Beauftragten und Mitbeauftragten die anvertrauten Daten im Rahmen der Vertragserfüllung verarbeiten oder durch Versiche-rungsunternehmen bzw. Vorsorgeeinrichtungen verar-beiten lassen.', style: 'paragraphJustify' },
              ]
            }
          ],
          columnGap: 35,
          pageBreak: 'after'
        },
        {
          columns: [
            {
              width: '50%',
              stack: [
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '8.', style: 'title' },
                    { text: 'Datenschutz', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Um die vereinbarten Dienstleistungen erbringen zu kön-nen, bearbeiten die Parteien die dafür notwendigen Per-sonendaten (bspw. Name, Adresse, Telefonnummer, AHV-Nummer, Schadenhistorie, Gesundheitsdaten, Lohnangaben etc.). Jede Partei hält die anwendbaren Datenschutzbestimmungen ein. Weitere Informationen für BSC Broker Service Center GmbH finden sich in der Datenschutzerklärung: https://brokerservice.ch/daten-schutz und die der Name der Mitbeauftragten URL mit Link auf Datenschutzerklärung der Webseite des Mitbe-auftragten.', style: 'paragraphJustify' },
                { text: 'Es liegt in der Verantwortung der Auftraggeberin, ihren datenschutzrechtlichen Pflichten (z.B. Infor-mationspflichten) insbesondere in Bezug auf ihre Mitarbeitenden nachzukommen.', style: 'paragraphJustifyBold'},
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '9.', style: 'title' },
                    { text: 'Kündigung des Vertragsverhältnisses', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Die Parteien vereinbaren für den Fall einer Kündigung, eine Frist von drei Monaten und die Textform einzuhal-ten.', style: 'paragraphJustify' },
                ]
            },
            {
              width: '50%',
              stack: [
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '10.', style: 'title' },
                    { text: 'Salvatorische Klausel', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Sollte eine der Bestimmungen dieser allgemeinen Ge-schäftsbedingungen ungültig, nichtig oder nicht durch-setzbar sein, so wird die Gültigkeit und Durchsetzbar-keit der übrigen Bestimmungen hiervon nicht berührt.', style: 'paragraphJustify' },

                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '11.', style: 'title' },
                    { text: 'Anwendbares Recht und Gerichtsstand', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Sämtliche Vereinbarungen und die übrigen rechtlichen Beziehungen zwischen den Parteien unterstehen schweizerischem Recht, unter Ausschluss allfälliger Staatsverträge.', style: 'paragraphJustify' },
                {
                  // Überschrift in Spalte 1 mit fester Nummern-Spalte
                  columns: [
                    { width: 20, text: '12.', style: 'title' },
                    { text: 'Änderungen der AGB', style: 'title' }
                  ],
                  columnGap: 0
                },
                { text: 'Für sämtliche Streitigkeiten, welche im Zusammenhang mit Vereinbarungen oder anderen Rechtsbeziehungen zwischen den Parteien stehen, ist der Gerichtsstand Zü-rich.', style: 'paragraphJustify' },
              ]
            }
          ],
          columnGap: 35,
          margin: [0, 80, 0, 0],
        },
        {
          margin: [0, 28, 0, 0],
          style: 'table',
          table: {
            widths: ['*', 110, 20, '*', 110, 20, '*', 110],
            body: [
              [
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Ort, Datum,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text: 'Dübendorf, 21.02.2025',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Schöftland,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 0, 0, 0], text: 'Ort, Datum,'},
                { border: [false, false, false, true], margin: [0, 0, 0, 0], text: '',},
              ],
            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
            hLineColor: (i, node) => '#173F35', // horizontale Linien in Rot
            vLineColor: (i, node) => '#173F35', // vertikale Linien in Rot
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', 20, '*', 20, '*'],
            body: [
              [
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Auftraggeberin', style: 'title' },
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: '',},
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Beauftragte', style: 'title' },
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: '',},
                { border: [false, false, false, false], margin: [0, 12, 0, 10], text: 'Mitbeauftragte', style: 'title' },
              ],
              [
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: this._mandatFormService.personalInformation.first_name + ' ' + this._mandatFormService.personalInformation.last_name },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: ''},
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: '' },
                { border: [false, false, false, false], margin: [0, 0, 0, 12], text: ''},
                { border: [false, false, false, true], margin: [0, 0, 0, 12], text: '' },
              ],
              [
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'Vorname und Name des Kunden', style: 'title', color: '#998542'},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'BSC Broker Service Center GmbH', style: 'title', color: '#000000'},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: '',},
                { border: [false, false, false, false], margin: [0, 5, 0, 0], text: 'Life Gate Consulting AG', style: 'title', color: '#998542'},
              ],
              [
                { border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                  stack: [
                    {
                      image: img,
                      alignment: 'center',
                      width: 120,
                      margin: [0, 0, 0, 0]
                    }
                  ]
                },
                { border: [false, false, false, false], margin: [0, 5, 0, 5], text: '',},
                { border: [false, false, false, true], margin: [0, 5, 0, 5], text: ''},
                { border: [false, false, false, false], margin: [0, 5, 0, 5], text: '',},
                { border: [false, false, false, true], margin: [0, 5, 0, 5], text: ''},
              ],

            ]
          },
          layout: {
            paddingLeft: () => 0,
            paddingRight: () => 0,
            paddingTop: () => 0,
            paddingBottom: () => 0,
            hLineColor: (i, node) => '#173F35', // horizontale Linien in Rot
            vLineColor: (i, node) => '#173F35', // vertikale Linien in Rot
          }
        },

      ],
      defaultStyle: {
        font: 'Arial',
        fontSize: 10,
        color: '#173F35',
        lineHeight: 1,
      },
      styles: {
        // Bestehende Stile bleiben erhalten
        paragraph: { font: 'Arial', marginBottom: 12, color: '#173F35' },
        paragraphJustify: { font: 'Arial', alignment: 'justify', marginBottom: 12, color: '#173F35' },
        paragraphJustifyBold: { font: 'Arial', bold: true, alignment: 'justify', marginBottom: 12, color: '#173F35' },

        georgiaBold: { font: 'Georgia', bold: true, fontSize: 10, color: '#173F35' },
        headline: { font: 'Georgia', fontSize: 28, color: '#173F35' },
        headlineMargin: { font: 'Georgia', fontSize: 28, marginBottom: 12, color: '#173F35' },
        header: { font: 'Arial', bold: true, fontSize: 12, marginBottom: 10, color: '#173F35' },
        title: { font: 'Arial', bold: true, fontSize: 10, marginBottom: 5, color: '#173F35' },
      },

      pageMargins: [30, 30, 30, 50] as Margins,
    } as TDocumentDefinitions;

    this.agbPdfToOpen = pdfMake.createPdf(docDefinition);
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBase64((data: any) => {
      this.agbBinaryPdf = data;
    });
  }
}
